// todo move to be on BE
const restrictedList = [
  // below are ppl that marked us as spam from listing alerts
  "nichola_gaylor@yahoo.com",
  "brittonpaul@hotmail.com",
  "johnimichael1@verizon.net",
  "philipjsm65@outlook.com",
  "dlcamp2017@yahoo.com",
  "vedafletcher@att.net",
  "jky722@hotmail.com",
  "bj0252@yahoo.com",
  "staceyr.939@yahoo.com",
  "frnkgrgl@yahoo.com",
  "mars55552001@yahoo.com",
  "jdw5575@yahoo.com",
  "macglobalme@yahoo.com",
  "ajitbnd@yahoo.com",
  "cpal622@yahoo.com",
  "jamessite@hotmail.com",
  "rickbolanos@aol.com",
  "wison217@yahoo.com",
  "steve_carbone@yahoo.com",
  "dwayne@dwayneajones.com",
  "matypotz@yahoo.com",
  "jmyles2@yahoo.com",
  "charlesgensley@yahoo.com",
  // marketplace violators
  "smithshevon414@gmail.com", // spam messaged people on marketplace, ultimately don't really want to do this
  "shanel@reinetworklp.com", // lures listings away to https://www.myhousedeals.com/
  // below are restricted loan borrowers
  "cmwiley2018@gmail.com", // cancelled 2 times, almost cancelled 3rd time day of closing
  "jaeamun@gmail.com", // got a quote, didnt like it, left 1 start review
  "marisafernandez@ymail.com", // got a quote and clicked proceed and then kept marking everything as spam
  // "ten.sixty@yahoo.com" // potentially, time waster so far
  // mrapropertyinvestment@gmail.com // he shopped for BMWs from 6 dealerships as we were closing, 2nd pull had his score much lower so couldn't close, left us a 1 star review as result
  "louisponce1@gmail.com", // this person missed his RM call, asked me to call him back, missed that call, then when I called him again he cursed me out for not immediately telling him our terms. He is highly unstable and needs to be blacklisted from our platform
  "aimeeaballo@outlook.com", // had LF and then marked us as spam
  "misswendygirl@msn.com", // got quote and marked as spam same day without replying
  "bmdief@gmail.com", // got to committee and then cancelled, this was his 3rd strike doing it
  "kateyanov@gmail.com", // over reacted about slight last minute changes to their deal due to cap providers operational issues caused by a weather event in their home office. Threatened legal action on the first inconvinience.
  "jonma2004@yahoo.com", // borrower found another lender who is offering a similar rate, 1-1-1 prepayment penalty, and 0.5% origination fee after intending to proceed with us. Borrower didn't have the professional courtesy to let us know, meanwhile we are working our asses off to get the final item from the borrower so this loan can close in 2 days
  "wuzi5@aol.com", // listed home, then complained about email about a new message
];

export default restrictedList;
