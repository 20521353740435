export const GA_TRACKING_ID = "GTM-K6WN7DF";

const send_to = "AW-603437128/B24SCKTQk64YEMjw3p8C";
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// type GTagEvent = {
//   action: string,
//   category: string,
//   label: string,
//   value: number,
// };

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag(
    "event",
    action,
    {
      send_to: "AW-603437128/B24SCKTQk64YEMjw3p8C",
    }

    //   , {
    //     event_category: category,
    //     event_label: label,
    //     value,
    //   }
  );
};
