export default async function validatePermissionAssignment(
  user,
  cypherString,
  mode
) {
  const targetUrl = `${process.env.CMS_URL}/user-permissions/vpsu`;

  let resData;

  const token = await user.getIdToken();

  try {
    const res = await fetch(targetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ cypherString, mode }),
    });

    resData = await res.json();
  } catch (error) {
    console.log("error with validatePermissionAssignment");
    console.log(error);
  }

  return resData;
}
