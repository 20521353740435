export default async function getUserProfile(user) {
  const targetUrl = `${process.env.CMS_URL}/user-profiles?UID=${user.uid}`;

  // this also produces token
  // not sure if this is just for updated token
  // since user already has one attached
  // for now using users included to save a trip
  // const idToken = await getIdToken(user);

  let resData;
  const token = await user.getIdToken();
  try {
    const res = await fetch(targetUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    resData = await res.json();
  } catch (error) {
    console.log("error", error);
  }

  return resData;
}
