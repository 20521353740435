import processEmail from "components/Authenticator/utils/processEmail";
import signUp from "components/Authenticator/utils/signUp";
import checkIfUserExists from "components/Authenticator/utils/checkIfUserExists";
import validatePermissionedEmail from "components/Authenticator/utils/validatePermissionedEmail";

// Continue also check and functions as sign up
// it looks up if user is registered to decide what
// screen to show them next

async function handleContinue(
  firebaseAuth,
  email,
  setEmailError,
  setEmailErrorMessage,
  setViewMode,
  cypherString
) {
  // check email or phone field for correct format
  const validInput = processEmail(email, setEmailError, setEmailErrorMessage);

  if (!validInput) {
    return;
  }

  let error = false;

  if (cypherString) {
    const permissionAssignmentValidation = await validatePermissionedEmail(
      cypherString,
      email
    );

    if (permissionAssignmentValidation?.response?.status === 403) {
      error = true;
      setViewMode("privelgedWrongEmailError");
    }
  }
  // try to sign up user to see if
  // email is already in the system
  if (!error) {
    try {
      const checkIfUserExistsResp = await checkIfUserExists(email);

      if (checkIfUserExistsResp.userExists) {
        // USER EXISTS
        setViewMode("login");
      } else {
        // USER DOESNT EXIST
        // if sign up successful
        // ask them for new password
        setViewMode("signUp");
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        // promp them to enter their password
        // and show 'forgot passwor'(reset password under the hood)
        setViewMode("login");
        return;
      }
    }
  }
}

export default handleContinue;
