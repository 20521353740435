import validateEmail from "lib/validateEmail";

function processEmail(email, setEmailError, setEmailErrorMessage) {
  if (email.length === 0) {
    setEmailError(true);
    setEmailErrorMessage("Please enter an email");
    return false;
  }

  // validate email
  if (!validateEmail(email)) {
    setEmailError(true);
    setEmailErrorMessage("Please enter a valid email");
    return false;
  }

  return true;
}

export default processEmail;
