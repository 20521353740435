async function checkIfUserExists(email) {
  const targetUrl = `${process.env.CMS_URL}/user-profiles/check-if-user-exists?email=${email}`;

  const res = await fetch(targetUrl);
  const data = await res.json();

  return data;
}

export default checkIfUserExists;
