import processEmail from "components/Authenticator/utils/processEmail";
import { sendPasswordResetEmail } from "firebase/auth";

async function handlePasswordReset(
  firebaseAuth,
  email,
  setEmailError,
  setEmailErrorMessage,
  setDeliveryMethod
) {
  // check email or phone field for correct format

  // check email or phone field for correct format
  const validInput = processEmail(email, setEmailError, setEmailErrorMessage);

  if (!validInput) {
    return;
  }

  let resp = "";
  try {
    resp = await sendPasswordResetEmail(firebaseAuth, email);
  } catch (error) {
    console.log(error);
  }

  if (resp === undefined) {
    // this is success case response from firebase
    // show message sent notification
    setDeliveryMethod("email");
  }
  //   setViewMode("confirmResetCode");
}

export default handlePasswordReset;
