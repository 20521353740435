import getUserProfile from "lib/getUserProfile";
import createUserProfile from "lib/createUserProfile";
import * as gtag from "components/GTagManager";
// gets user profile (or creates user profile)
// sets user and user profile
// and then redirects signed in user to the correct place

async function redirectAfterSignInOrSignUp(
  user,
  setUser,
  setUserProfile,
  setViewMode,
  router,
  isNewUser = false,
  redirectURLTarget = false
) {
  // get user profile
  let userProfile;
  try {
    userProfile = await getUserProfile(user);
  } catch (error) {
    console.log(error);
  }

  // if new user, new UID
  if (userProfile?.statusCode === 404) {
    userProfile = await createUserProfile(user, router);

    // this is new, before didn't have this flag
    // so had to rely on userProfile with UID existing
    // in our own system
    if (isNewUser) {
      gtag.event({ action: "sign_up" });
    }
    // this is a sign up
    // so redirect appropriately
    // if at /login page
    // show options

    if (redirectURLTarget) {
      setUserProfile(userProfile);
      setUser(user);
      router.push(redirectURLTarget);
    } else {
      if (router.pathname.includes("/login")) {
        setViewMode("successfulSignUp");
        setUserProfile(userProfile);
        setUser(user);
        return;
      } else if (
        router.pathname.includes("/survey") ||
        router.pathname.includes("/loans") ||
        router.pathname.includes("/insurance") ||
        router.asPath.split("?")[0].includes("/tools/dscr-calculator") ||
        router.asPath
          .split("?")[0]
          .includes("/blog/construction-loan-calculator")
      ) {
        // dont redirect after loans
        //   create survey listing
        // redirect to the listing
        // /survey/subscribe/deal-flow
        //   redirect to /listings
        // ask seller survey
        // tour survey
        // redirect back to the listing
        //
        //   TODO
        // right now layout handles these redirects
        // but here is a better place for it

        //  no redirect
        setUserProfile(userProfile);
        setUser(user);

        return;
      } else {
        // all other pages
        // on sign up,
        // take to listings
        setUserProfile(userProfile);
        setUser(user);
        // UPDATE 03/20/2024, will no longer push user to another page
        // router.push("/listings?status=loggedin");
        return;
      }
    }
  } else {
    // OLD USER
    // this is a login
    gtag.event({ action: "login" });

    if (
      // router.pathname.includes("/my/calculations") ||
      // router.pathname.includes("/survey") ||
      // router.pathname.includes("/loans") ||
      // router.asPath.split("?")[0].includes("/blog/dscr-calculator") ||
      // router.asPath
      //   .split("?")[0]
      //   .includes("/blog/construction-loan-calculator") ||
      // router.asPath.split("?")[0].includes("/insurance") ||
      // router.asPath.split("?")[0].includes("/blog/")
      router.asPath.split("?")[0].includes("/login")
    ) {
      // all other pages
      // on sign up,
      // take to listings
      setUserProfile(userProfile);
      setUser(user);
      // UPDATE 03/20/2024, will no longer push user to another page
      router.push("/listings?status=loggedin");
      return;
    } else {
      // dont redirect after loans
      //   create survey listing
      // redirect to the listing
      // /survey/subscribe/deal-flow
      //   redirect to /listings
      // ask seller survey
      // tour survey
      // redirect back to the listing
      //
      //   TODO
      // right now layout handles these redirects
      // but here is a better place for it
      setUserProfile(userProfile);
      setUser(user);

      return;
    }
  }
}

export default redirectAfterSignInOrSignUp;
