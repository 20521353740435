import redirectAfterSignInOrSignUp from "components/Authenticator/utils/redirectAfterSignInOrSignUp";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  linkWithCredential,
} from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

async function handleAccountLinkingWithProvider(
  firebaseAuth,
  router,
  setUser,
  pendingCredentials,
  oldSingInMethod,
  setUserProfile,
  setViewMode
) {
  let provider;
  switch (oldSingInMethod) {
    case "google.com":
      provider = googleProvider;
      break;
    case "facebook.com":
      provider = facebookProvider;
      break;
    // case "github.com":
    //   provider = new firebase.auth.GithubAuthProvider();
    //   break;
    // case "twitter.com":
    //   provider = new firebase.auth.TwitterAuthProvider();
    //   break;
    default:
      return;
  }

  try {
    const resp = await signInWithPopup(firebaseAuth, provider);
    // The signed-in user info.
    const user = resp.user;
    await linkWithCredential(user, pendingCredentials);

    await redirectAfterSignInOrSignUp(
      user,
      setUser,
      setUserProfile,
      setViewMode,
      router
    );
  } catch (error) {
    console.log(error);
  }
}

export default handleAccountLinkingWithProvider;
