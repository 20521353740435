export default async function validatePermissionedEmail(cypherString, email) {
  const targetUrl = `${process.env.CMS_URL}/user-permissions/vpue`;

  let resData;

  try {
    const res = await fetch(targetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cypherString, email }),
    });

    resData = await res.json();
  } catch (error) {
    console.log("error with validatePermissionedEmail");
    console.log(error);
  }

  return resData;
}
