import { createUserWithEmailAndPassword } from "firebase/auth";

async function signUp(firebaseAuth, email, password) {
  const resp = await createUserWithEmailAndPassword(
    firebaseAuth,
    email,
    password
  );

  return resp;
}

export default signUp;
