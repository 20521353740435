// NOTE:
// find on backend creates user profile if not found
// having this function resulted in duplicate profiles being created
// NOTE 2:
// this is no longer true
// find won't automatically create a user profile
// this is done so we can differentiate between a sign up or sign in
// since firebase auth doesn't have a good method for this.

export default async function createUserProfile(user, router) {
  const createTargetUrl = `${process.env.CMS_URL}/user-profiles`;

  let resData;
  const token = await user.getIdToken();
  try {
    const res = await fetch(createTargetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        UID: user.uid,
        Email: user.email,
        FirstName: user?.displayName?.split(" ")[0],
        LastName:
          user?.displayName?.split(" ")[
            user?.displayName?.split(" ").length - 1
          ],
        PhoneNumber: user?.phoneNumber,
        PictureURL: user?.photoURL,
        TourNotificationPreferences: { email: true, none: false },
        AttributionSource: router && router.asPath.split("?")[0],
      }),
    });
    resData = await res.json();
  } catch (error) {
    console.log("error creating user profile");
    console.log(error);
  }

  return resData;
}
